import { useEffect, useMemo, useState } from 'react';

import { debounce } from 'es-toolkit';

export const useDebouncedValue = <T>(value: T, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const debounced = useMemo(
    () =>
      debounce((value: T) => {
        setDebouncedValue(value);
      }, delay),
    [delay],
  );

  useEffect(() => {
    debounced(value);

    return debounced.cancel;
  }, [value, debounced]);

  return debouncedValue;
};
