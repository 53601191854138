import { Node, mergeAttributes } from '@tiptap/core';

import { ATTRIBUTES } from '../_constants';

interface BoxedTextOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    boxedText: {
      setBoxedText: () => ReturnType;
      unsetBoxedText: () => ReturnType;
    };
  }
}

const BoxedText = Node.create<BoxedTextOptions>({
  name: 'boxedText',

  group: 'block',

  content: 'block+',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [
      {
        tag: `blockquote[${ATTRIBUTES.BOXED_TEXT}]`,
        getAttrs: (node) => !!node.getAttribute(ATTRIBUTES.BOXED_TEXT) && null,
      },
    ];
  },

  addAttributes() {
    return {
      [ATTRIBUTES.BOXED_TEXT]: {
        default: true,
        parseHTML: (element: HTMLElement) =>
          element.getAttribute(ATTRIBUTES.BOXED_TEXT),
        renderHTML: (attributes) => {
          return {
            [ATTRIBUTES.BOXED_TEXT]: attributes[ATTRIBUTES.BOXED_TEXT],
          };
        },
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'blockquote',
      mergeAttributes(HTMLAttributes, {
        class: 'bg-Gray-200 w-full py-16 px-20 rounded-large',
      }),
      0,
    ];
  },

  addCommands() {
    return {
      setBoxedText:
        () =>
        ({ commands }) => {
          return commands.wrapIn(this.name);
        },
      unsetBoxedText:
        () =>
        ({ commands }) => {
          return commands.lift(this.name);
        },
    };
  },
});

export default BoxedText;
