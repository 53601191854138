'use client';

import type { PortalProps } from '../Portal/Portal';

import {
  useCallback,
  useEffect,
  useId,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import type { SwipeCallback } from 'react-swipeable';
import { useSwipeable } from 'react-swipeable';
import { Transition } from 'react-transition-group';

import { cn } from '@common/utils';

import { Portal } from '../Portal/Portal';

import { useBottomDrawerStore } from '.';

export type SwipingState = 'idle' | 'swiping' | 'additional-swiping';

const VELOCITY_MULTIPLIER = 30; //* 속도 증폭 계수
const VELOCITY_SCALE = 25; //* 속도 스케일
const ANIMATION_DURATION = 1_500; //* 애니메이션 지속 시간 증가 (밀리초)
const MIN_DISTANCE = 10; //* 최소 이동 거리 (픽셀)
const DECELERATION_RATE = 1; //* 감속률 (1에 가까울수록 천천히 감속)

//* 감속 함수
const easeOutExpo = (t: number) => 1 - Math.pow(2, -10 * t);

//* 최종 이동 거리 계산 함수
const calculateFinalDelta = (initialVelocity: number) => {
  const scaledVelocity = initialVelocity * VELOCITY_SCALE;

  return (
    Math.sign(scaledVelocity) *
    Math.max(Math.abs(scaledVelocity) * VELOCITY_MULTIPLIER, MIN_DISTANCE)
  );
};

interface FullHandledBottomDrawerProps
  extends Pick<PortalProps, 'renderTo' | 'onLoaded'> {
  opened?: boolean;
  className?: {
    backdrop?: string;
    drawer?: string;
    contents?: string;
  };
  children: React.ReactNode;
  blur?: boolean;
  minHeight?: number;
  isSwipingToMinHeight?: boolean;
  onChangeIsSwipingToMinHeight?: (isSwipingToMinHeight: boolean) => void;
  loading?: boolean;
  onChangeSwiping?: (deltaY: number, state: SwipingState) => void;
}

type SwipingParams = Parameters<SwipeCallback>[number];

export const FullHandledBottomDrawer = ({
  opened = true,
  children,
  className,
  onLoaded,
  renderTo,
  minHeight: _minHeight = 0,
  isSwipingToMinHeight,
  onChangeIsSwipingToMinHeight,
  loading,
  onChangeSwiping,
}: FullHandledBottomDrawerProps) => {
  const id = useId();

  const { currentDrawerId, drawerMap, setCurrentDrawerId, setDrawerMap } =
    useBottomDrawerStore();

  useEffect(() => {
    if (opened) return setCurrentDrawerId(id);

    const prevDrawerId = drawerMap.get(id);

    if (prevDrawerId && drawerMap.has(prevDrawerId)) {
      setCurrentDrawerId(prevDrawerId);
    }

    drawerMap.delete(id);
    setDrawerMap(drawerMap);
  }, [drawerMap, id, opened, setCurrentDrawerId, setDrawerMap]);

  const isCurrentDrawer = currentDrawerId === id;

  const isVisible = opened && isCurrentDrawer;

  const [height, setHeight] = useState(0);

  const minHeight = height < _minHeight ? height : _minHeight;
  const minDeltaY = -minHeight;

  const [deltaY, setDeltaY] = useState(minDeltaY);

  const tempDeltaY = useRef<number | null>(null);

  const [swipingState, setSwipingState] = useState<SwipingState>('idle');

  const drawerRef = useRef<HTMLDivElement | null>(null);
  const animationFrameRef = useRef<number | null>(null);

  useEffect(() => {
    setDeltaY((prevDeltaY) => {
      if (prevDeltaY < minHeight) {
        return -minHeight;
      }

      return prevDeltaY;
    });
  }, [minHeight]);

  useEffect(() => {
    if (isSwipingToMinHeight) {
      setDeltaY(minDeltaY);
      tempDeltaY.current = null;
      const animationTimer = setTimeout(() => {
        onChangeIsSwipingToMinHeight?.(false);
      }, 300);

      return () => clearTimeout(animationTimer);
    }
  }, [isSwipingToMinHeight, minDeltaY, onChangeIsSwipingToMinHeight]);

  useEffect(() => {
    if (!onChangeSwiping) return;

    onChangeSwiping?.(deltaY, swipingState);
  }, [deltaY, swipingState, onChangeSwiping]);

  useLayoutEffect(
    () =>
      setHeight(() => {
        const height = drawerRef.current?.offsetHeight;

        if (!height || height < minHeight) return minHeight;

        return height;
      }),
    [minHeight, children],
  );

  const stopTimerRef = useRef<NodeJS.Timeout | null>(null);
  const isStopVelocity = useRef(false);

  const handleSwiping = useCallback(
    ({
      deltaY,
      byWheel,
    }: Pick<SwipingParams, 'deltaY'> & {
      byWheel?: boolean;
    }) => {
      if (loading) return;

      if (!drawerRef.current) return;

      setSwipingState('swiping');

      if (stopTimerRef.current) {
        clearTimeout(stopTimerRef.current);
        stopTimerRef.current = null;
        isStopVelocity.current = false;
      }

      if (animationFrameRef.current !== null) {
        cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = null;
      }

      setDeltaY((prevDeltaY) => {
        if (tempDeltaY.current === null) {
          tempDeltaY.current = prevDeltaY;
        } else if (!byWheel) {
          prevDeltaY = tempDeltaY.current;
        }

        const caculatedDeltaY = prevDeltaY + deltaY;

        //* 위로 스크롤 최대
        if (caculatedDeltaY < -height) {
          return -height;
        }

        if (minDeltaY < caculatedDeltaY) {
          return minDeltaY;
        }

        if (byWheel) {
          if (minDeltaY < caculatedDeltaY) {
            return minDeltaY;
          }
        }

        return caculatedDeltaY;
      });

      stopTimerRef.current = setTimeout(() => {
        isStopVelocity.current = true;
      }, 50);
    },
    [height, loading, minDeltaY],
  );

  const swipeableHandlers = useSwipeable({
    trackMouse: true,
    onSwiping: ({ deltaY }) => {
      handleSwiping({
        deltaY,
      });
    },
    onSwiped: ({ dir, vxvy, velocity }: SwipingParams) => {
      if (isStopVelocity.current) {
        velocity = 0;
      }

      if (stopTimerRef.current) {
        clearTimeout(stopTimerRef.current);
        stopTimerRef.current = null;
        isStopVelocity.current = false;
      }

      if (loading || ['Right', 'Left'].includes(dir)) return;

      tempDeltaY.current = null;

      const vy = vxvy[1];

      if (!vy) return;

      const hasVelocity = 0.2 < velocity;

      if (
        (deltaY === minDeltaY && dir === 'Down') ||
        (deltaY === -height && dir === 'Up')
      ) {
        return setSwipingState('idle');
      }

      if (hasVelocity) {
        setSwipingState('additional-swiping');

        const initialVelocity = vy * velocity;
        const finalDeltaY = calculateFinalDelta(initialVelocity);
        let targetDeltaY = deltaY + finalDeltaY;

        // 범위 제한
        targetDeltaY = Math.max(-height, Math.min(minDeltaY, targetDeltaY));

        const startDeltaY = deltaY;
        const deltaChange = targetDeltaY - startDeltaY;
        const startTime = performance.now();

        let currentVelocity = initialVelocity * VELOCITY_SCALE;

        const animate = (currentTime: number) => {
          const elapsedTime = currentTime - startTime;
          const progress = elapsedTime / ANIMATION_DURATION;
          const easedProgress = easeOutExpo(progress);

          currentVelocity *= DECELERATION_RATE;
          const currentDeltaY = startDeltaY + deltaChange * easedProgress;

          tempDeltaY.current = currentDeltaY;
          setDeltaY(currentDeltaY);

          if (progress < 1 && 0.1 < Math.abs(currentVelocity)) {
            animationFrameRef.current = requestAnimationFrame(animate);
          } else {
            // 부드러운 정지를 위한 추가 애니메이션
            const finalAnimate = (time: number) => {
              const finalProgress = Math.min(
                (time - currentTime) / ANIMATION_DURATION,
                1,
              );
              const finalEasedProgress = easeOutExpo(finalProgress);
              const finalDeltaY =
                currentDeltaY +
                (targetDeltaY - currentDeltaY) * finalEasedProgress;

              tempDeltaY.current = finalDeltaY;
              setDeltaY(finalDeltaY);

              if (finalProgress < 0.1) {
                animationFrameRef.current = requestAnimationFrame(finalAnimate);
              } else {
                if (Math.abs(finalDeltaY - minDeltaY) < 1) {
                  tempDeltaY.current = minDeltaY;
                  setDeltaY(minDeltaY);
                } else if (height + finalDeltaY < 1) {
                  tempDeltaY.current = -height;
                  setDeltaY(-height);
                }

                setSwipingState('idle');
              }
            };

            animationFrameRef.current = requestAnimationFrame(finalAnimate);
          }
        };

        animationFrameRef.current = requestAnimationFrame(animate);
      } else {
        setSwipingState('idle');
      }
    },
  });

  return isCurrentDrawer ? (
    <Portal renderTo={renderTo} onLoaded={onLoaded}>
      <Transition
        in={isVisible && !!height}
        nodeRef={drawerRef}
        timeout={{ appear: 0, enter: 300, exit: 300 }}
      >
        {(state) => {
          return (
            <div
              {...swipeableHandlers}
              ref={(el) => {
                swipeableHandlers.ref(el);

                drawerRef.current = el;
              }}
              className={cn(
                'absolute w-full bg-Gray-white z-20 left-0 rounded-t-large shadow-lg bottom-0 hide-scrollbar',
                {
                  'animate-in slide-in-from-bottom duration-300':
                    state === 'entering',
                  invisible: state === 'unmounted',
                  'transition-transform duration-300': isSwipingToMinHeight,
                },
                className?.drawer,
              )}
              style={{
                transform: ['entering', 'entered'].includes(state)
                  ? `translate3d(0, ${height + deltaY}px, 0)`
                  : undefined,
              }}
              onWheel={(e) => {
                handleSwiping({
                  deltaY: -e.deltaY,
                  byWheel: true,
                });
              }}
            >
              <article
                className={cn(
                  'mx-20 max-w-full relative box-border flex-1 flex flex-col',
                  {
                    'animate-in animate-fade duration-100 delay-200':
                      state === 'entering',
                  },
                  className?.contents,
                )}
              >
                <div className="w-full flex justify-center">
                  <div className="w-44 h-4 bg-Gray-300 cursor-pointer mt-8 mb-12" />
                </div>
                {children}
              </article>
            </div>
          );
        }}
      </Transition>
    </Portal>
  ) : null;
};
