import type { Transaction } from '@tiptap/pm/state';
import type { Editor } from '@tiptap/react';

export const customEditor = {
  getImageUrls: (doc: Transaction['doc'] | Editor['$doc']) => {
    const imageList: string[] = [];
    doc.content.forEach((content) => {
      if (content.type.name === 'image') {
        imageList.push(content.attrs.src);
      }
    });

    return imageList;
  },
};
