import { useContext } from 'react';

import { SvgCamera } from '@common/icon';

import { cn } from '@common/utils';

import { CustomEditorContext } from '.';

export interface ImageButtonProps {
  onClick?: () =>
    | Promise<string | void | undefined | null>
    | string
    | void
    | undefined
    | null;

  limit?: number;
  onLimitExceed?: () => void;
  className?: string;
}

export const ImageButton = ({
  className,
  limit,
  onClick,
  onLimitExceed,
}: ImageButtonProps) => {
  const { editor } = useContext(CustomEditorContext);

  return (
    <button
      className={cn('h-fit w-fit', className)}
      type="button"
      onClick={async (e) => {
        e.preventDefault();

        if (!editor)
          throw new Error(
            'ImageButton은 CustomEditorProvider 내부에서만 사용 가능합니다.',
          );

        if (limit) {
          const imageList: string[] = [];

          editor.state.doc.content.forEach((content) => {
            if (content.type.name === 'image') {
              imageList.push(content.attrs.src);
            }
          });

          if (limit <= imageList.length) {
            onLimitExceed?.();

            return;
          }
        }

        const url = await onClick?.();

        if (!url) return;

        const from = editor.state.selection.from;
        const marks = editor.state.doc.resolve(from).marks();

        let chainedCommand = editor
          .chain()
          .focus()
          .insertContent([
            {
              type: 'image',
              attrs: {
                src: url,
              },
            },
            {
              type: 'paragraph',
              content: '',
            },
          ]);

        marks.forEach((mark) => {
          chainedCommand = chainedCommand.setMark(mark.type, mark.attrs);
        });

        chainedCommand.run();
      }}
    >
      <SvgCamera className="size-24 text-Gray-700" name="Camera" />
    </button>
  );
};
