import Color from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import TextStyle from '@tiptap/extension-text-style';

export const TEXT_COLORS = [
  ['Gray-black', '#000000'],
  ['Gray-800', '#4A4D55'],
  ['Gray-600', '#8C919F'],
  ['Gray-500', '#B8BCC8'],
  ['B-500', '#3366ff'],
] as const;

type TextColor = (typeof TEXT_COLORS)[number][0];

export const TextColor = Color.configure({
  types: [ListItem.name, TextStyle.name, 'textStyle'],
});

export const getTextColorValue = (color: TextColor) => {
  const value = TEXT_COLORS.find(([name]) => name === color)?.[1];

  if (!value) {
    throw new Error(`Unknown color: ${color}`);
  }

  return value;
};
