import { Mark, mergeAttributes } from '@tiptap/core';

import { ATTRIBUTES } from '../_constants';

export const FONT_WEIGHTS = ['font-400', 'font-700', 'font-800'] as const;

export type FontWeightType = (typeof FONT_WEIGHTS)[number];

interface FontWeightOptions {
  HTMLAttributes: Record<string, any>;
}

interface FontWeightAttributes {
  fontWeight: FontWeightType | null;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontWeight: {
      setFontWeight: (weight: FontWeightType) => ReturnType;
      unsetFontWeight: () => ReturnType;
    };
  }
}

export const FontWeight = Mark.create<FontWeightOptions>({
  name: 'fontWeight',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      fontWeight: {
        default: 'font-400' satisfies FontWeightType,
        parseHTML: (element: HTMLElement) =>
          element.getAttribute(ATTRIBUTES.FONT_WEIGHTS),
        renderHTML: (attributes: FontWeightAttributes) => {
          if (!attributes.fontWeight) {
            return {};
          }

          return {
            [ATTRIBUTES.FONT_WEIGHTS]: attributes.fontWeight,
            class: attributes.fontWeight,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[${ATTRIBUTES.FONT_WEIGHTS}]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }: FontWeightOptions) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setFontWeight:
        (fontWeight: FontWeightType) =>
        ({ chain }) => {
          return chain().setMark(this.name, { fontWeight }).run();
        },
    };
  },
});
