'use client';

import { useContext } from 'react';

import { EditorContent } from '@tiptap/react';

import { cn } from '@common/utils';

import './Contents.scss';

import { CustomEditorContext } from '.';

interface CustomEditorContentsProps {
  className?: string;
}

export const Contents = ({ className }: CustomEditorContentsProps) => {
  const { editor } = useContext(CustomEditorContext);

  return (
    <EditorContent
      className={cn(
        'editor-container',
        'text-left p-16 overflow-auto text-Gray-900',
        className,
      )}
      editor={editor as Parameters<typeof EditorContent>[number]['editor']}
    />
  );
};
