'use client';

import type { HTMLTagProps } from '@common/types';

import { Portal, Slot } from '@radix-ui/themes';

import { useBodyScrollLock } from '@common/hooks';
import { cn } from '@common/utils';

export interface ModalProps extends HTMLTagProps<'article'> {
  blurredBackground?: boolean;
  opened?: boolean;
  onClose?: () => void;
  asChild?: boolean;
  wrapperClassName?: string;
}

export const Modal = ({
  //* Modal props
  opened = false,
  onClose,
  asChild = false,

  //* HTML article props
  className,
  wrapperClassName,
  children,
  ...restArticleProps
}: ModalProps) => {
  useBodyScrollLock(opened);

  const ModalContents = asChild ? Slot : 'article';

  return opened ? (
    <Portal
      className={cn(
        'fixed top-0 left-0 flex items-center justify-center w-full h-full animate-in fade-in z-10 text-Gray-700 font-400 typo-body3',
        wrapperClassName,
      )}
    >
      <div
        className="absolute z-[-1] w-full h-full bg-[#1A1A1A] bg-opacity-70"
        onClick={onClose}
      />
      <ModalContents
        {...restArticleProps}
        className={cn(
          'flex flex-col box-border min-w-300 h-fit max-h-full m-8 px-24 py-40 rounded-large bg-Gray-white whitespace-pre-wrap overflow-auto text-center',
          className,
        )}
      >
        {children}
      </ModalContents>
    </Portal>
  ) : (
    <></>
  );
};
