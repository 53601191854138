import type { SvgComponentProps } from './SvgComponentProps';

import type { FunctionComponent, SVGProps } from 'react';
import React from 'react';

import { cn } from '@common/utils';

import styles from './Svg.module.scss';

export const createSvgComponent =
  (Svg: FunctionComponent<SVGProps<SVGSVGElement>>) =>
  // eslint-disable-next-line react/display-name
  ({ className, originalFill, ...restProps }: SvgComponentProps) => (
    <Svg
      {...restProps}
      className={cn(
        'size-24',
        {
          [styles.icon]: !originalFill,
        },
        className,
      )}
    />
  );
