'use client';

import type { PortalProps } from '../Portal/Portal';

import type { CSSProperties } from 'react';
import {
  createContext,
  useEffect,
  useId,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import type { SwipeableHandlers } from 'react-swipeable';
import { useSwipeable } from 'react-swipeable';
import { Transition } from 'react-transition-group';

import { debounce } from 'es-toolkit';
import { create } from 'zustand';

import { useBodyScrollLock } from '@common/hooks';
import { cn } from '@common/utils';

import { Portal } from '../Portal/Portal';

import { Contents } from './_components/Contents';
import { Handle } from './_components/Handle';
import { HandleWrapper } from './_components/HandleWrapper';

interface BottomDrawerProps {
  opened?: boolean;
  onClose?: () => void;
  className?: {
    backdrop?: string;
    drawer?: string;
  };
  children: React.ReactNode;
  unmountOnExit?: boolean;
  mountOnEnter?: boolean;
  duration?: number;
  renderTo?: PortalProps['renderTo'];
}

interface BottomDrawerContextProps {
  swipeableHandlers: SwipeableHandlers;
  isVisible: boolean;
  onClose: BottomDrawerProps['onClose'];
}

export const BottomDrawerContext = createContext<
  BottomDrawerContextProps | undefined
>(undefined);

interface BottomDrawerStore {
  drawerMap: Map<string, string | null>;
  setDrawerMap: (drawerMap: Map<string, string | null>) => void;

  currentDrawerId: string | null;
  setCurrentDrawerId: (id: string | null) => void;
}

export const useBottomDrawerStore = create<BottomDrawerStore>((set, get) => ({
  drawerMap: new Map(),
  setDrawerMap: (drawerMap) => set({ drawerMap }),

  currentDrawerId: null,
  setCurrentDrawerId: (id) => {
    const { drawerMap, currentDrawerId: prevDrawerId } = get();

    if (id) {
      drawerMap.set(id, prevDrawerId);
    }

    set({ currentDrawerId: id, drawerMap });
  },
}));

const Provider = ({
  opened = false,
  onClose,
  children,
  className,
  unmountOnExit,
  mountOnEnter,
  duration = 300,
  renderTo,
}: BottomDrawerProps) => {
  const id = useId();

  const { currentDrawerId, drawerMap, setCurrentDrawerId, setDrawerMap } =
    useBottomDrawerStore();

  useEffect(() => {
    if (opened) return setCurrentDrawerId(id);

    const prevDrawerId = drawerMap.get(id);

    if (prevDrawerId && drawerMap.has(prevDrawerId)) {
      setCurrentDrawerId(prevDrawerId);
    }

    drawerMap.delete(id);
    setDrawerMap(drawerMap);
  }, [drawerMap, id, opened, setCurrentDrawerId, setDrawerMap]);

  const isCurrentDrawer = currentDrawerId === id;

  const isVisible = opened && isCurrentDrawer;

  const [deltaY, setDeltaY] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const drawerRef = useRef<HTMLDivElement>(null);

  const isCloseLine = useRef(false);

  useLayoutEffect(() => {
    if (!deltaY && isCloseLine.current) {
      isCloseLine.current = false;
      onClose?.();
    }
  }, [deltaY, onClose]);

  const swipeableHandlers = useSwipeable({
    onSwipedDown: debounce(
      ({ velocity }) => {
        setDeltaY(0);
        if (1 < velocity) {
          onClose?.();
        }
      },
      33,
      { edges: ['leading'] },
    ),
    onSwiping: ({ deltaY }) => {
      if (!drawerRef.current) return;

      const { offsetHeight } = drawerRef.current;

      const closingLine = offsetHeight * 0.7;

      isCloseLine.current = closingLine < deltaY;

      setDeltaY(deltaY);
    },
  });

  useBodyScrollLock(isVisible);

  return isCurrentDrawer ? (
    <Portal renderTo={renderTo}>
      <BottomDrawerContext.Provider
        value={{ isVisible, onClose, swipeableHandlers }}
      >
        <Transition
          appear
          in={isVisible}
          mountOnEnter={mountOnEnter}
          nodeRef={containerRef}
          timeout={{ appear: 0, enter: 0, exit: duration }}
          unmountOnExit={unmountOnExit}
        >
          {(state) => {
            const [backdropStyles, transitionStyles] = (() => {
              const transformStyles =
                deltaY <= 0
                  ? undefined
                  : ({
                      transform: `translate3d(0, ${deltaY}px, 0)`,
                      transition: 'none',
                    } satisfies CSSProperties);

              const durationStyles: CSSProperties = {
                transitionDuration: `${duration}ms`,
              };

              switch (state) {
                case 'entering':
                case 'exiting':
                  return [
                    { opacity: '0', ...durationStyles },
                    {
                      transform: 'translate3d(0, 100%, 0)',
                      ...transformStyles,
                      ...durationStyles,
                    },
                  ];
                case 'entered':
                  return [
                    { opacity: '1', ...durationStyles },
                    {
                      transition: `transform ${duration}ms cubic-bezier(0.4, 0, 0.2, 1)`,
                      ...transformStyles,
                      ...durationStyles,
                    },
                  ];
                case 'exited':
                case 'unmounted':
                  return [
                    { display: 'none', ...durationStyles },
                    { display: 'none', ...transformStyles, ...durationStyles },
                  ];

                default:
                  return [];
              }
            })();

            return (
              <div ref={containerRef}>
                <div
                  className={cn(
                    'absolute z-10 bg-[#1a1a1ab2] bg-opacity-70 top-0 left-0 h-full w-full duration-300',
                    className?.backdrop,
                  )}
                  style={backdropStyles}
                  onClick={onClose}
                />
                <div
                  ref={drawerRef}
                  className={cn(
                    'absolute w-full bg-Gray-white z-20 left-0 bottom-0 rounded-t-large max-h-[calc(100dvh-1rem)] flex flex-col shadow-lg',
                    className?.drawer,
                  )}
                  style={transitionStyles}
                >
                  {children}
                </div>
              </div>
            );
          }}
        </Transition>
      </BottomDrawerContext.Provider>
    </Portal>
  ) : null;
};

export const BottomDrawer = Object.assign(Provider, {
  HandleWrapper,
  Handle,
  Contents,
});
