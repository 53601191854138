import Link from '@tiptap/extension-link';

import { cn } from '@common/utils';

export interface CustomLinkOptions {
  href: string;
  theme: 'text' | 'primary-fill-button' | 'sub-fill-button';
  isOutLink: boolean;
  size: 'H56' | 'H40';
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customLink: {
      setCustomLink: (options: CustomLinkOptions) => ReturnType;
    };
  }
}

export const CustomLink = Link.configure({
  autolink: false,
  openOnClick: false,
  protocols: ['http', 'https'],
  linkOnPaste: false,
}).extend({
  addAttributes() {
    return {
      ...this.parent?.(),
    };
  },
  addCommands() {
    return {
      ...this.parent?.(),
      setCustomLink:
        ({ href, theme, size, isOutLink }) =>
        ({ chain, state }) => {
          const isTextLink = theme === 'text';

          const buttonClassName = `${
            {
              H56: 'py-16 px-24 typo-body3',
              H40: 'py-9 px-16 typo-body4',
            }[size]
          } inline-block rounded-medium`;

          const { from, to } = state.selection;

          return chain()
            .extendMarkRange('link')
            .unsetMark('link')
            .setMark('link', {
              href,
              class: cn(
                'cursor-pointer',
                {
                  [buttonClassName]: !isTextLink,
                },
                {
                  text: 'hover:underline text-B-500 font-500',
                  'primary-fill-button':
                    'bg-Gray-black font-700 text-Gray-white',
                  'sub-fill-button': 'bg-Gray-200 font-700 text-Gray-black',
                }[theme],
              ),
              rel: isOutLink ? 'noopener noreferrer' : undefined,
              target: isOutLink ? '_blank' : '_self',
            })
            .setTextSelection({ from, to })
            .run();
        },
    };
  },
  addPasteRules() {
    return [];
  },
});
