interface ImageOptimizationOptions {
  format?: 'webp' | 'avif' | 'original';
  width?: number;
  height?: number;
  quality?: number;
}

export const optimizeImageUrl = (
  originalUrl?: string,
  options: ImageOptimizationOptions = {},
) => {
  if (!originalUrl) return '';

  try {
    const url = new URL(originalUrl);

    if (!url.origin.startsWith('https://cdn.welfarehello')) return originalUrl;

    const params = new URLSearchParams(url.search);

    const { format = 'webp', width = 800, height, quality = 80 } = options;

    if (format !== 'original') params.set('f', format);

    if (height) params.set('h', height.toString());
    if (quality) params.set('q', quality.toString());

    params.set('w', width.toString());

    url.search = params.toString();

    return url.toString();
  } catch (e) {
    console.error(originalUrl);

    return originalUrl;
  }
};
