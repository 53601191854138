import { Mark, mergeAttributes } from '@tiptap/core';

import { ATTRIBUTES } from '../_constants';

export const HIGHLIGHTS = [
  'bg-Y-100',
  'bg-G-100',
  'bg-R-50',
  'bg-B-100',
] as const;
export type HighlightType = (typeof HIGHLIGHTS)[number];

interface HighlightOptions {
  HTMLAttributes: Record<string, any>;
}

interface HighlightAttributes {
  highlight: HighlightType | null;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    highlight: {
      setHighlight: (highlight: HighlightType) => ReturnType;
      unsetHighlight: () => ReturnType;
    };
  }
}

export const Highlight = Mark.create<HighlightOptions>({
  name: 'highlight',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      highlight: {
        parseHTML: (element: HTMLElement) =>
          element.getAttribute(ATTRIBUTES.HIGH_LIGHT),
        renderHTML: (attributes: HighlightAttributes) => {
          if (!attributes.highlight) {
            return {};
          }

          const highlightColor =
            HIGHLIGHTS.find(
              (highlight) => highlight === attributes.highlight,
            ) ?? [];

          return {
            [ATTRIBUTES.HIGH_LIGHT]: attributes.highlight,
            class: highlightColor,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[${ATTRIBUTES.HIGH_LIGHT}]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }: HighlightOptions) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setHighlight:
        (highlight: HighlightType) =>
        ({ chain }) => {
          return chain().setMark(this.name, { highlight }).run();
        },
      unsetHighlight:
        () =>
        ({ chain }) => {
          return chain().unsetMark(this.name).run();
        },
    };
  },
});
