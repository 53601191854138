import { useLayoutEffect } from 'react';

import styles from './useBodyScrollLock.module.scss';

interface UseBodyScrollLockOptions {
  selector?: string;
}

export const useBodyScrollLock = (
  enabled: boolean,
  options?: UseBodyScrollLockOptions,
) => {
  const selector = options?.selector;
  useLayoutEffect(() => {
    if (typeof document === 'undefined' || !enabled) return;

    let target = document.body;

    if (selector) {
      const optionTarget = document.getElementById(selector);

      if (optionTarget) {
        target = optionTarget;
      }
    }

    const { classList } = target;

    classList.add(styles['body-size'], styles['fixed-body']);

    return () => {
      classList.remove(styles['body-size'], styles['fixed-body']);
    };
  }, [enabled, selector]);
};
