import { CustomError } from '.';

export function assert(
  condition: unknown,
  error: Error | string = new Error(),
): asserts condition {
  if (!condition) {
    if (typeof error === 'string') {
      throw new CustomError({ return_message: error });
    } else {
      throw new CustomError();
    }
  }
}
