import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import {
  Button as RadixButton,
  Slot,
  type ButtonProps as RadixButtonProps,
} from '@radix-ui/themes';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@common/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center transition duration-150 ease-in-out active:outline-none',
  {
    variants: {
      theme: {
        'primary-fill':
          'bg-Gray-black text-Gray-white hover:bg-Gray-900 focus:ring-bg-Gray-black/50 disabled:bg-Gray-300',
        'primary-line':
          'border border-solid border-bg-Gray-black bg-inherit text-Gray-black hover:text-Gray-700 hover:border-Gray-700 focus:ring-bg-Gray-black/50 disabled:border-bg-Gray-black disabled:text-Gray-black disabled:bg-inherit',
        'primary-text':
          'bg-inherit text-Gray-black hover:text-Gray-700 disabled:text-Gray-black disabled:bg-inherit',
        'sub-fill':
          'bg-Gray-200  text-Gray-900 hover:bg-Gray-100 focus:ring-Gray-300/50 disabled:bg-Gray-100 disabled:text-Gray-400',
        'sub-line':
          'border border-solid border-Gray-300 bg-inherit text-Gray-900 focus:ring-Gray-300/50 disabled:border-Gray-300 disabled:text-Gray-900 disabled:bg-inherit',
        'sub-text':
          'bg-inherit text-Gray-800 hover:text-Gray-500 disabled:text-Gray-400 disabled:bg-inherit',
      },
      dimensions: {
        H56: 'h-56 px-24 py-16 gap-6 font-700 typo-body3',
        H48: 'h-48 px-24 py-12 gap-6 font-700 typo-body3',
        H40: 'h-40 px-16 py-10 gap-4 font-700 typo-body4',
        H32: 'h-32 px-16 py-7 gap-4 font-700 typo-caption1',
        H26: 'h-26 px-10 py-5 gap-4 font-600 typo-caption2',
      },
      roundness: {
        rectangle: 'rounded-small',
        capsule: 'rounded-pill',
      },
    },
    defaultVariants: {
      theme: 'primary-fill',
      dimensions: 'H56',
      roundness: 'rectangle',
    },
  },
);

export interface ButtonProps
  extends RadixButtonProps,
    VariantProps<typeof buttonVariants> {
  children?: ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      theme,
      dimensions,
      roundness,
      type = 'button',
      children,
      asChild,
      ...props
    },
    ref,
  ) => {
    const Button = asChild ? Slot : RadixButton;

    return (
      <Button
        ref={ref}
        className={cn(
          buttonVariants({ dimensions, theme, roundness, className }),
          {
            'opacity-50 !cursor-not-allowed': props.disabled,
          },
        )}
        type={type}
        {...props}
      >
        {children}
      </Button>
    );
  },
);

Button.displayName = 'Button';
