interface CheckOptions {
  skipPathname?: boolean;
  skipSearch?: boolean;
}

export const isCurrentUrl = (href: string, options?: CheckOptions) => {
  if (typeof window === 'undefined') return false;

  const targetUrl = new URL(href, location.origin);

  return (
    (options?.skipPathname || targetUrl.pathname === location.pathname) &&
    (options?.skipSearch || targetUrl.search === location.search)
  );
};
