'use client';

import type { HTMLTagProps } from '@common/types';

import { createContext, useContext } from 'react';

import { useSubscribedState } from '@common/hooks';
import { CustomError, cn, createUuid } from '@common/utils';

interface RadioGroupProps<T>
  extends Pick<HTMLTagProps<'fieldset'>, 'children' | 'className' | 'name'> {
  onChange?: (value: T) => void;
  value?: T;
  readOnly?: boolean;
  theme?: 'blue' | 'black';
}

const RadioContext = createContext<Pick<
  RadioGroupProps<any>,
  'onChange' | 'value' | 'readOnly' | 'name' | 'theme'
> | null>(null);

const Group = <T,>({
  value: defaultValue,
  onChange,
  children,
  readOnly,
  className,
  name = createUuid(),
  theme = 'blue',
}: RadioGroupProps<T>) => {
  const [value, setValue] = useSubscribedState(defaultValue);

  return (
    <fieldset className={cn('flex gap-8 typo-body4', className)}>
      <RadioContext.Provider
        value={{
          name,
          onChange: (value) => {
            if (readOnly) return;
            setValue(value);
            onChange?.(value);
          },
          value,
          readOnly,
          theme,
        }}
      >
        {children}
      </RadioContext.Provider>
    </fieldset>
  );
};

interface RadioItemProps extends Omit<HTMLTagProps<'input'>, 'children'> {
  className?: string;
  value: string;
  children?: React.ReactNode;
}

const Item = ({ className, children, value }: RadioItemProps) => {
  const context = useContext(RadioContext);

  if (!context)
    throw new CustomError({
      return_message: 'Radio.Item은 Radio.Group 내부에서만 사용할 수 있습니다.',
    });

  const { value: groupValue, onChange, readOnly, theme } = context;

  const inputClassName = (() => {
    const COMMON_CLASS_NAME =
      'w-16 h-16 rounded-pill box-border appearance-none border-1 border-Gray-300 checked:border-4 bg-Gray-white';

    if (readOnly) {
      switch (theme) {
        case 'black':
          return `${COMMON_CLASS_NAME} checked:border-Gray-black cursor-not-allowed`;

        case 'blue':
        default:
          return `${COMMON_CLASS_NAME} checked:border-B-300 cursor-not-allowed`;
      }
    }

    switch (theme) {
      case 'black':
        return `${COMMON_CLASS_NAME} checked:border-Gray-black`;

      case 'blue':
      default:
        return `${COMMON_CLASS_NAME} checked:border-B-500`;
    }
  })();

  return (
    <label className={cn('flex gap-4 justify-center items-center', className)}>
      <input
        checked={groupValue === value}
        className={cn(inputClassName)}
        disabled={readOnly}
        name={context.name}
        type="radio"
        value={value}
        onChange={(e) => {
          if (e.target.checked) {
            onChange?.(value);
          }
        }}
      />
      {children}
    </label>
  );
};

export const Radio = {
  Group,
  Item,
};
