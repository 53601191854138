'use client';

import type { HTMLTagProps } from '@common/types';
import type { VariantProps } from 'class-variance-authority';

import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { SvgDelete } from '@common/icon';
import { cva } from 'class-variance-authority';

import { cn, isArrayItem } from '@common/utils';

import { InputDecoratorContext } from '../InputDecorator/InputDecorator';

import styles from './Input.module.scss';

const inputVariants = cva(
  'relative bg-Gray-white transition-[border] duration-150 typo-body3 outline-none border-Gray-300 border-1 flex justify-center items-center text-Gray-900',
  {
    variants: {
      dimensions: {
        H40: 'h-40',
        H48: 'h-48',
        H56: 'h-56',
      },
      roundness: {
        rectangle: 'rounded-small',
        capsule: 'rounded-pill',
      },
    },
    defaultVariants: {
      dimensions: 'H48',
      roundness: 'rectangle',
    },
  },
);

interface InputProps
  extends Omit<HTMLTagProps<'input'>, 'type'>,
    VariantProps<typeof inputVariants> {
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  type?: HTMLTagProps<'input'>['type'] | 'local-string-number' | 'text-date';
  invalid?: boolean;
  onClear?: () => void;
}

const CUSTOM_FORMAT_TYPE = ['local-string-number', 'text-date', 'tel'] as const;

const formatValue = (
  value: string,
  type: (typeof CUSTOM_FORMAT_TYPE)[number],
) => {
  switch (type) {
    case 'local-string-number': {
      return value ? BigInt(value).toLocaleString() : value;
    }

    case 'tel': {
      if (value.length === 10)
        return value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

      return value.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    }

    case 'text-date': {
      const textDate = value.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');

      return isNaN(new Date(textDate).getTime()) ? value : textDate;
    }
  }
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      leftContent,
      rightContent,
      className,
      dimensions,
      type = 'text',
      onBlur,
      onChange,
      onInvalid,
      onFocus,
      invalid,
      id,
      inputMode,
      onKeyDown,
      onClear,
      ...restProps
    },
    externalRef,
  ) => {
    const ref = useRef<HTMLInputElement>(null);

    useImperativeHandle(externalRef, () => ref.current as HTMLInputElement, []);

    const [isFormattedValue, setIsFormattedValue] = useState(true);

    const inputDecoratorContext = useContext(InputDecoratorContext);

    useLayoutEffect(() => {
      if (!isArrayItem(CUSTOM_FORMAT_TYPE, type)) return;

      const { current: inputElement } = ref;

      if (!inputElement || !isFormattedValue) return;

      const originalValue = inputElement.value;

      inputElement.value = formatValue(originalValue, type);

      return () => {
        inputElement.value = originalValue;
      };
    }, [isFormattedValue, type]);

    const customType = (() => {
      switch (type) {
        case 'local-string-number':
        case 'text-date':
          return isFormattedValue ? 'text' : 'number';

        case 'tel':
          return isFormattedValue ? 'tel' : 'number';

        case 'button':
          return (restProps.value ?? ref.current?.value) ? 'button' : 'text';

        default:
          return type;
      }
    })();

    const isButton = type === 'button';

    return (
      <div
        className={cn(
          styles['input-container'],
          inputVariants({
            dimensions,
          }),
          {
            'cursor-pointer': isButton,
            'border-R-500': invalid ?? inputDecoratorContext?.validationMessage,
          },
          'has-[input:focus]:border-Gray-900',
          'has-[input:disabled]:bg-Gray-300 has-[input:disabled]:cursor-not-allowed has-[input:disabled]:text-Gray-500',
          className,
        )}
        onClick={() => {
          ref.current?.focus();
          ref.current?.click();
        }}
      >
        <div
          className={cn(
            'w-fit h-fit relative min-w-fit max-w-fit pl-16 text-nowrap',
            {
              'pr-8': leftContent,
            },
          )}
        >
          {leftContent}
        </div>
        <input
          {...restProps}
          ref={ref}
          className={cn(
            styles.input,
            'min-w-0 w-full',
            'outline-none flex-1 text-inherit bg-transparent',
            {
              'text-start caret-transparent': isButton,
            },
          )}
          id={id ?? inputDecoratorContext?.label}
          inputMode={customType === 'number' ? 'numeric' : inputMode}
          type={customType}
          onBlur={(e) => {
            setIsFormattedValue(true);
            onBlur?.(e);
          }}
          onChange={(e) => {
            let clearValue = e.target.value;

            switch (type) {
              case 'text-date': {
                clearValue = clearValue.slice(0, 8);
                break;
              }

              case 'tel': {
                clearValue = clearValue.slice(0, 11);
                break;
              }
            }

            e.target.value = clearValue;

            onChange?.(e);
          }}
          onFocus={(e) => {
            setIsFormattedValue(false);
            onFocus?.(e);
          }}
          onInvalid={(e) => {
            e.preventDefault();
            onInvalid?.(e);
          }}
          onKeyDown={(e) => {
            const { key } = e;
            if (customType === 'number') {
              const isOnlyNumber =
                /^[0-9]*$/.test(key) ||
                ['Backspace', 'Enter', 'Tab'].includes(key);

              if (!isOnlyNumber) {
                return e.preventDefault();
              }
            }
            onKeyDown?.(e);
          }}
        />
        {customType === 'search' ? (
          <button
            className="bg-Gray-400 rounded-pill size-16 hidden justify-center items-center"
            type="reset"
            onClick={() => {
              if (ref.current?.value) {
                ref.current.value = '';
              }

              onClear?.();
            }}
          >
            <SvgDelete
              className="text-Gray-white stroke-Gray-white size-9"
              name="delete"
              strokeWidth={2}
            />
          </button>
        ) : null}
        <div
          className={cn(
            'w-fit h-fit relative min-w-fit max-w-fit pr-16 text-nowrap',
            {
              'pl-8': rightContent,
            },
          )}
        >
          {rightContent}
        </div>
      </div>
    );
  },
);

Input.displayName = 'Input';
