'use client';

import type { SliderProps as RadixSliderProps } from '@radix-ui/react-slider';

import * as RadixSlider from '@radix-ui/react-slider';

import { cn } from '@common/utils';

interface SliderProps extends RadixSliderProps {
  min: number;
  max: number;
  value: number[];
  range: number;
  className?: string;
}

export const Slider = ({
  className,
  value,
  min,
  max,
  range,
  ...props
}: SliderProps) => {
  const extendedRange = range * 1.1;
  const left = `${Math.max(0, ((value[0] - extendedRange - min) / (max - min)) * 100).toFixed(5)}%`;
  const right = `${Math.max(0, 100 - ((value[0] + extendedRange - min) / (max - min)) * 100).toFixed(5)}%`;

  return (
    <RadixSlider.Root
      max={max}
      min={min}
      value={value}
      {...props}
      className={cn(
        'relative flex items-center select-none touch-none h-6 bg-Gray-300',
        className,
      )}
    >
      <RadixSlider.Track className="relative grow h-6 cursor-pointer">
        <div className="relative h-full">
          <div
            className="absolute h-full"
            style={{
              left,
              right,
              background: `linear-gradient(
                to right, 
                rgba(132, 169, 255, 0) 0%, 
                rgba(132, 169, 255, 1) 15%, 
                rgba(132, 169, 255, 1) 85%, 
                rgba(132, 169, 255, 0) 100%
              )`,
            }}
          />
          <RadixSlider.Range className="absolute rounded-pill" />
        </div>
      </RadixSlider.Track>
      <RadixSlider.Thumb
        className={cn(
          'block w-20 h-20 bg-Gray-white border-4 border-B-500 rounded-pill',
          'transform transition-transform duration-200 ease-in-out',
          'focus:outline-none focus:ring-0 focus:shadow-none',
          'hover:scale-125 active:scale-95 cursor-pointer',
        )}
      />
    </RadixSlider.Root>
  );
};
