export const isEnumValue = <TEnum extends Record<string, string>>(
  enumType: TEnum,
  value?: string | null,
): value is TEnum[keyof TEnum] =>
  value ? Object.values(enumType).includes(value) : false;

export const isEnumKey = <TEnum extends Record<string, string>>(
  enumType: TEnum,
  key: string | number | symbol,
): key is keyof TEnum => Object.keys(enumType).includes(String(key));

export const isArrayItem = <T>(array: readonly T[], value: any): value is T =>
  array.includes(value);

export const hasObjectKey = <TValue, TKey extends string>(
  obj: Record<any, TValue> | object | unknown,
  key: TKey,
): obj is Record<TKey, TValue> => {
  return typeof obj === 'object' && obj !== null && key in obj;
};
