import { useRef } from 'react';
import { toast as toastifyToast } from 'react-toastify';

import { isDev, type ServerResponse } from '@common/utils';

import FailIcon from './icon/fail.svg';
import InfoIcon from './icon/info.svg';
import SuccessIcon from './icon/success.svg';
import WarningIcon from './icon/warning.svg';

export interface ToastOptions {
  type?: 'fail' | 'success' | 'warning' | 'info';
  message: string;
  holdTime?: number;
}

interface ToastErrorOptions extends Omit<ToastOptions, 'message'> {
  error: unknown;
  exceptionOptions?: { [code: number]: string };
}

const toastQueue = new Map<string, ToastOptions>();

export const useToast = () => {
  const toast = ({
    message,
    holdTime = 3_000,
    type = 'success',
  }: Omit<ToastOptions, 'toastKey' | 'createdAt'>) => {
    const Icon = {
      success: SuccessIcon,
      fail: FailIcon,
      warning: WarningIcon,
      info: InfoIcon,
    }[type];

    const toastKey = `${message}-${type}`;

    if (toastQueue.has(toastKey)) {
      return;
    }

    toastQueue.set(toastKey, { message, holdTime, type });

    toastifyToast(message, {
      autoClose: holdTime,
      icon: <Icon className="w-20 h-20" />,
      onClose: () => {
        toastQueue.delete(toastKey);
      },
    });
  };

  const { current } = useRef({
    toast,
    toastError: ({
      error,
      type = 'fail',
      exceptionOptions,
      ...restOptions
    }: ToastErrorOptions) => {
      const { return_code: errorCode, return_message: errorMessage } =
        error as ServerResponse;

      console.error(error);

      const toastErrorMessage = (message: string) =>
        toast({ ...restOptions, type, message });

      if (!errorCode || !errorMessage) {
        return toastErrorMessage(
          '서버로 부터 정상적인 응답을 받지 못했습니다.',
        );
      }

      const exceptedErrorMessage = exceptionOptions?.[errorCode];

      if (exceptedErrorMessage) {
        return toastErrorMessage(exceptedErrorMessage);
      }

      //TODO: 30000 이상 에러 메시지 백엔드와 논의 재필요
      if (30000 <= errorCode) {
        return toastErrorMessage(errorMessage);
      }

      return toastErrorMessage(
        `일시적인 오류가 발생했습니다. 잠시 후 다시 시도해주세요. (${errorCode})`,
      );
    },
    devToast: (message: string) => {
      if (isDev) {
        toast({ message, type: 'warning' });
      }
    },
  });

  return current;
};
