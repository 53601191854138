'use client';

import type { HTMLTagProps } from '@common/types';

import { createContext, useEffect, useRef, useState } from 'react';

import { cn } from '@common/utils';

import styles from './InputDecorator.module.scss';

interface InputDecoratorProps extends HTMLTagProps<'div'> {
  label?: string;
  required?: boolean;
  validationMessage?: string;
  message?: string;
  htmlFor?: string;
}

interface InputDecoratorContextValue
  extends Pick<InputDecoratorProps, 'label' | 'validationMessage'> {}

export const InputDecoratorContext =
  createContext<InputDecoratorContextValue | null>(null);

export const InputDecorator = ({
  label,
  required,
  validationMessage,
  message,
  className,
  children,
  htmlFor,
  ...restProps
}: InputDecoratorProps) => {
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const hasMessage = !!(validationMessage || message);

  const [messageWrapHeight, setMessageWrapHeight] =
    useState<React.CSSProperties>();

  useEffect(() => {
    const { current } = messageContainerRef;
    if (current && hasMessage) {
      return setMessageWrapHeight({
        height: current.clientHeight,
      });
    }

    return setMessageWrapHeight(undefined);
  }, [hasMessage]);

  return (
    <div {...restProps} className={cn('flex flex-col', className)}>
      {label ? (
        <label
          className={cn('typo-body4 font-400 text-Gray-800 mb-8 text-start', {
            "after:content-['*'] after:text-R-500 after:ml-1": !!required,
          })}
          htmlFor={htmlFor ?? label}
        >
          {label}
        </label>
      ) : null}
      <InputDecoratorContext.Provider value={{ label, validationMessage }}>
        {children}
      </InputDecoratorContext.Provider>
      {hasMessage ? (
        <div
          className={cn('transition-size-location overflow-hidden h-0 mt-0', {
            'mt-4': hasMessage,
          })}
          style={messageWrapHeight}
        >
          <div
            ref={messageContainerRef}
            className="mx-5 typo-caption1 font-400 flex gap-8"
          >
            <span className={styles['validation-message']}>
              {validationMessage}
            </span>
            <span className="text-Gray-600">{message}</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};
