'use client';

import { useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export interface PortalProps {
  children: React.ReactNode;
  renderTo?: () => Element | null;
  onLoaded?: () => (() => void) | void;
  placeholder?: React.ReactNode | true;
  deps?: React.DependencyList;
}

export const Portal = ({
  children,
  renderTo,
  onLoaded,
  placeholder = null,
  deps,
}: PortalProps) => {
  const [element, setElement] = useState<Element | null>(null);

  useLayoutEffect(() => {
    if (typeof window === 'undefined' || !renderTo) return;

    setElement(renderTo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    if (!onLoaded || !element) return;

    const clear = onLoaded();

    if (clear)
      return () => {
        clear();
      };
  }, [element, onLoaded]);

  if (!renderTo) return children;

  return element
    ? createPortal(children, element)
    : placeholder === true
      ? children
      : placeholder;
};
