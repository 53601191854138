import type { Resolver } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

type Yup = typeof yup;

export type FormSchemaGuide<T extends Object> = Partial<{
  [K in keyof T]: yup.AnySchema;
}>;

export const createYupSchemaResolver = <T extends Object>(
  factory: (yup: Yup) => { [K in keyof T]: yup.AnySchema },
) => {
  const customYup = {
    ...yup,
    number: () =>
      yup
        .number()
        .transform((value, originalValue) =>
          originalValue === '' ? undefined : value,
        ),
  };

  const schema = yup.object().shape(factory(customYup));

  return yupResolver(schema as any) as unknown as Resolver<T>;
};

export { yup };

export const customYup = {
  stringDate: (options?: { onlyPast?: boolean; message?: string }) => {
    const message = options?.message ?? '정상적인 날짜 형식이 아닙니다.';

    let checkDate = yup
      .string()
      .required(message)
      .min(8, message)
      .max(10, message)
      .test('is-date', message, (value) => {
        let dateValue = value;

        const regex = /^\d{4}-\d{2}-\d{2}$/;
        if (!dateValue.match(regex)) {
          dateValue = `${value.substring(0, 4)}-${value.substring(4, 6)}-${value.substring(6, 8)}`;
          if (!dateValue.match(regex)) {
            return false;
          }
        }

        const date = new Date(dateValue);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        const parts = dateValue.split('-');
        const yearFromStr = parseInt(parts[0], 10);
        const monthFromStr = parseInt(parts[1], 10);
        const dayFromStr = parseInt(parts[2], 10);

        if (
          year !== yearFromStr ||
          month !== monthFromStr ||
          day !== dayFromStr
        ) {
          return false;
        }

        if (yearFromStr < 1900) {
          return false;
        }

        if (month === 2) {
          //! 윤년 확인
          if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
            return day <= 29;
          } else {
            return day <= 28;
          }
        } else if ([4, 6, 9, 11].includes(month)) {
          return day <= 30;
        } else {
          return day <= 31;
        }
      });

    if (options?.onlyPast) {
      checkDate = checkDate.test(
        'is-past-or-present',
        '과거의 날짜만 입력할 수 있습니다.',
        (value) => {
          if (!value) return false;
          const inputDate = new Date(
            value.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'),
          );
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          return inputDate <= today;
        },
      );
    }

    return checkDate;
  },
  stringPhone: (message = '올바른 전화번호를 입력해주세요.') =>
    yup
      .string()
      .min(10, message)
      .max(11, message)
      .test('is-010-prefix', message, (value) => {
        return /^010/.test(value ?? '');
      })
      .test('is-valid-fourth-digit', message, (value) => {
        if (value === undefined) return;
        const fourthDigit = value.charAt(3);

        return !['0', '1'].includes(fourthDigit);
      }),
};
