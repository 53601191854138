import type { Node } from 'prosemirror-model';

import { Extension } from '@tiptap/core';
import { Slice, Fragment } from 'prosemirror-model';
import { Plugin } from 'prosemirror-state';

export const PasteFilter = Extension.create({
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handlePaste(view, _event, slice) {
            const { content } = slice;

            const filteredNodeList: Node[] = [];

            content.forEach((node) => {
              if (node.type.name !== 'image') {
                filteredNodeList.push(node);
              }
            });

            const filteredSlice = new Slice(
              Fragment.fromArray(filteredNodeList),
              slice.openStart,
              slice.openEnd,
            );

            const transaction = view.state.tr.replaceSelection(filteredSlice);

            view.dispatch(transaction);

            return true;
          },
        },
      }),
    ];
  },
});
