import type { CustomRequest } from '../api';

import { hasKey } from './hasKey';

export interface ServerResponse<T = unknown> {
  result_code?: number;
  return_code?: number;
  return_message?: string;
  request?: CustomRequest;
  context?: T;
}

export enum ERROR_CODE {
  HIDDEN = 20019,
  UNKNOWN_BY_CLIENT = 40000,
  UNKNOWN_REQUEST = 40500,
  NO_REQUEST_FUNCTION = 40400,
  NO_ENV = 40401,
  UNKNOWN_UNAUTHORIZED = 40403,
  UNAUTHORIZED_BY_CLIENT = 40404,
}

export const isNormalizedResponse = (res: any): res is ServerResponse =>
  hasKey(res, 'result_code') &&
  hasKey(res, 'return_code') &&
  hasKey(res, 'return_message');

export class CustomError extends Error {
  status: number;
  return_code: number;
  return_message: string;
  constructor(params?: ServerResponse) {
    super(
      params?.return_message ??
        '일시적인 오류가 발생했습니다. 잠시 후 다시 시도해주세요.',
    );
    this.return_message = params?.return_message ?? '';
    this.status = params?.result_code ?? 500;
    this.return_code = params?.return_code ?? ERROR_CODE.UNKNOWN_BY_CLIENT;
    this.name = 'Client Error';

    if (params?.request) {
      this.name = 'Request Error';
      this.cause = JSON.stringify(params.request);
    }
  }
}

let errorNum = 0;

//* 서버 요청에 대한 응답을 항상 통일된 형태로 받기 위한 함수
export const normalizeServerRequest = <
  TParams extends any[],
  TResponse extends ServerResponse,
>(
  requestFn: (...args: TParams) => Promise<TResponse>,
) => {
  if (!requestFn) {
    errorNum += 1;
    throw new CustomError({
      return_message: `변환된 요청함수가 없습니다. (${errorNum})`,
      result_code: 400,
      return_code: ERROR_CODE.NO_REQUEST_FUNCTION,
    });
  }

  return async (...args: TParams) => {
    try {
      const res: TResponse = await requestFn(...args);

      const { return_code } = res;

      //* result_code가 0인 경우에만 정상적인 응답
      if (return_code === 0) return res;

      if (return_code) throw new CustomError(res);

      throw new CustomError({
        ...res,
        return_message: '잘못된 형식의 응답을 받았습니다.',
      });
    } catch (error) {
      if (error instanceof CustomError) throw error;

      if (error instanceof Error) {
        const errorObject: ServerResponse = {
          return_message: error.message,
          result_code: 500,
          return_code: ERROR_CODE.UNKNOWN_REQUEST,
        };

        if ('request' in error) {
          errorObject.request = error.request as CustomRequest;
        }

        throw new CustomError(errorObject);
      }

      throw new CustomError({
        return_code: ERROR_CODE.UNKNOWN_REQUEST,
      });
    }
  };
};
