import type { HTMLTagProps } from '@common/types';

import { useContext } from 'react';

import { cn } from '@common/utils';

import { BottomDrawerContext } from '..';

export interface BottomDrawerHandleProps extends HTMLTagProps<'button'> {}

export const Handle = ({
  className,
  onClick,
  type = 'button',
  ...restProps
}: BottomDrawerHandleProps) => {
  const context = useContext(BottomDrawerContext);

  if (!context)
    throw new Error(
      'BottomDrawer.Handle은 BottomDrawer 내부에서만 사용할 수 있습니다.',
    );

  return (
    <button
      {...restProps}
      className={cn('w-44 h-4 bg-Gray-300 cursor-pointer', className)}
      type={type}
      onClick={(e) => {
        onClick?.(e);
      }}
    />
  );
};
