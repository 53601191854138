import type { HTMLTagProps } from '@common/types';

import { cn } from '@common/utils';

interface BottomDrawerContentsProps extends HTMLTagProps<'div'> {}

export const Contents = ({
  className,
  ...restProps
}: BottomDrawerContentsProps) => {
  return (
    <div
      {...restProps}
      className={cn(
        'mx-30 mb-16 max-w-full relative box-border flex-1 overflow-auto webkit-scroll-touch hide-scrollbar',
        className,
      )}
    />
  );
};
