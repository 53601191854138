import type { ImageButtonProps } from './ImageButton';
import type { CustomLinkOptions } from './_utils/CustomLink';

import React, { useContext, useState } from 'react';

import { Button } from '@common/components/atom/Button/Button';
import { Input } from '@common/components/atom/Input/Input';
import { InputDecorator } from '@common/components/atom/InputDecorator/InputDecorator';
import { Modal } from '@common/components/atom/Modal/Modal';
import { Radio } from '@common/components/atom/Radio/Radio';
import { cn } from '@common/utils';

import { ImageButton } from './ImageButton';
import BoxedTextIcon from './_assets/boxed-text.svg';
import HrIcon from './_assets/hr.svg';
import { TEXT_ALIGN } from './_constants';
import { FONT_SIZES, type FontSizeType } from './_utils/FontSize';
import { FONT_WEIGHTS } from './_utils/FontWeight';
import { HIGHLIGHTS } from './_utils/Highlight';
import { TEXT_COLORS } from './_utils/TextColor';

import { CustomEditorContext } from '.';

interface ToolbarProps {
  imageUploadOptions?: ImageButtonProps;
}

export const Toolbar = ({ imageUploadOptions }: ToolbarProps) => {
  const { editor } = useContext(CustomEditorContext);

  const [isLinkModalOpened, setIsLinkModalOpened] = useState(false);

  const [customLinkOptions, setCustomLinkOptions] = useState<CustomLinkOptions>(
    {
      theme: 'text',
      size: 'H56',
      isOutLink: false,
      href: '',
    },
  );

  if (!editor) {
    return null;
  }

  const [selectedColor] =
    TEXT_COLORS.find(([, color]) => editor.isActive('textStyle', { color })) ??
    [];

  const selectedFontWeight = FONT_WEIGHTS.find((fontWeight) =>
    editor.isActive('fontWeight', { fontWeight }),
  );

  const selectedFontSize = FONT_SIZES.find((fontSize) => {
    return editor.isActive('fontSize', { fontSize });
  });

  const isBoxedText = editor.isActive('boxedText');

  const selectedTextAlign = TEXT_ALIGN.find((textAlign) =>
    editor.isActive('textAlign', { textAlign }),
  );

  return (
    <nav className="flex gap-8 bg-Gray-100 p-8 justify-center items-center flex-wrap">
      <select
        value={selectedFontSize}
        onChange={(e) => {
          editor
            .chain()
            .focus()
            .setFontSize(e.target.value as FontSizeType)
            .run();
        }}
      >
        {FONT_SIZES.map((fontSize) => (
          <option key={fontSize} className={fontSize} value={fontSize}>
            {fontSize}
          </option>
        ))}
      </select>
      <select
        value={selectedTextAlign}
        onChange={(e) => {
          editor?.chain().focus().setTextAlign(e.target.value).run();
        }}
      >
        {TEXT_ALIGN.map((textAlign) => (
          <option key={textAlign} value={textAlign}>
            {textAlign}
          </option>
        ))}
      </select>

      <section className="gap-8 flex px-8 py-2 border-Gray-600 border-1 rounded-small">
        {TEXT_COLORS.map(([label, color]) => (
          <button
            key={label}
            className={cn('font-800', {
              underline: selectedColor === label,
            })}
            style={{
              color,
            }}
            type="button"
            onClick={() => editor?.chain().focus().setColor(color).run()}
          >
            A
          </button>
        ))}
      </section>
      <section className="gap-8 flex px-8 py-2 border-Gray-600 border-1 rounded-small">
        {FONT_WEIGHTS.map((fontWeight) => (
          <button
            key={fontWeight}
            className={cn(fontWeight, {
              underline: selectedFontWeight === fontWeight,
            })}
            type="button"
            onClick={() =>
              editor?.chain().focus().setFontWeight(fontWeight).run()
            }
          >
            B
          </button>
        ))}
      </section>
      {HIGHLIGHTS.map((highlight) => {
        const isActive = editor.isActive('highlight', { highlight });

        return (
          <button
            key={highlight}
            className={cn(
              highlight,
              'flex h-24 w-24 justify-center items-center font-700 rounded-small min-w-24 min-h-24',
              {
                'border-1 border-Gray-800': isActive,
              },
            )}
            type="button"
            onClick={() => {
              isActive
                ? editor.chain().focus().unsetHighlight().run()
                : editor.chain().focus().setHighlight(highlight).run();
            }}
          />
        );
      })}
      <button
        className={cn('w-24 h-24 flex justify-center items-center box-border', {
          'border-Gray-800 border-1 rounded-xsmall': isBoxedText,
        })}
        type="button"
        onClick={() => {
          isBoxedText
            ? editor.chain().focus().unsetBoxedText().run()
            : editor.chain().focus().setBoxedText().run();
        }}
      >
        <BoxedTextIcon />
      </button>
      <button
        type="button"
        onClick={() => {
          editor?.chain().focus().setHorizontalRule().run();
        }}
      >
        <HrIcon />
      </button>
      <ImageButton {...imageUploadOptions} />
      <Button
        dimensions="H26"
        roundness="rectangle"
        onClick={() => {
          setIsLinkModalOpened(true);
        }}
      >
        링크
      </Button>
      <Modal
        opened={isLinkModalOpened}
        onClose={() => setIsLinkModalOpened(false)}
      >
        <article className="flex flex-col gap-16 mb-32">
          <InputDecorator label="주소">
            <Input
              value={customLinkOptions.href}
              onChange={(e) => {
                setCustomLinkOptions((prev) => ({
                  ...prev,
                  href: e.target.value,
                }));
              }}
            />
          </InputDecorator>
          <InputDecorator label="링크 형식">
            <Radio.Group
              value={customLinkOptions.theme}
              onChange={(value) => {
                setCustomLinkOptions((prev) => ({
                  ...prev,
                  theme: value as CustomLinkOptions['theme'],
                }));
              }}
            >
              <Radio.Item value="text">문자</Radio.Item>
              <Radio.Item value="primary-fill-button">버튼 1</Radio.Item>
              <Radio.Item value="sub-fill-button">버튼 2</Radio.Item>
            </Radio.Group>
          </InputDecorator>
          <InputDecorator label="창 열기">
            <Radio.Group
              value={customLinkOptions.isOutLink ? 'out' : 'in'}
              onChange={(value) => {
                setCustomLinkOptions((prev) => ({
                  ...prev,
                  isOutLink: value === 'out',
                }));
              }}
            >
              <Radio.Item value="out">외부에서 열기</Radio.Item>
              <Radio.Item value="in">내부에서 열기</Radio.Item>
            </Radio.Group>
          </InputDecorator>
        </article>
        <footer className="w-full gap-8 flex">
          <Button
            className="flex-1"
            disabled={!customLinkOptions.href}
            onClick={() => {
              setIsLinkModalOpened(false);

              editor.chain().focus().setCustomLink(customLinkOptions).run();
            }}
          >
            설정
          </Button>
          <Button
            className="flex-1"
            theme="sub-fill"
            onClick={() => {
              setIsLinkModalOpened(false);
            }}
          >
            취소
          </Button>
        </footer>
      </Modal>
    </nav>
  );
};
