'use client';

import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { type CheckboxProps as RadixCheckboxProps } from '@radix-ui/react-checkbox';
import { cva, type VariantProps } from 'class-variance-authority';

import { useSubscribedState } from '@common/hooks';
import { cn } from '@common/utils';

const checkboxVariants = cva(
  [
    'rounded-xsmall',
    'size-16',
    'flex items-center justify-center',
    'transition-colors duration-200 ease-in-out',
  ],
  {
    variants: {
      checked: {
        false: [
          'bg-white',
          'border border-Gray-300',
          'hover:border-Gray-500 bg-Gray-white',
        ],
        true: ['bg-Gray-black', 'border-none'],
      },
      disabled: {
        true: ['bg-Gray-300', 'cursor-not-allowed'],
        false: [],
      },
    },
    defaultVariants: {
      checked: false,
      disabled: false,
    },
  },
);

interface CheckboxProps
  extends Omit<RadixCheckboxProps, 'checked' | 'disabled' | 'onCheckedChange'>,
    VariantProps<typeof checkboxVariants> {
  checked?: boolean;
  disabled?: boolean;
  onCheckedChange?: (checked: boolean) => void;
}

const Checkbox = ({
  className,
  checked = false,
  disabled = false,
  onCheckedChange,
  ...props
}: CheckboxProps) => {
  const [turned, setTurned] = useSubscribedState(checked);

  return (
    <label
      className={cn(
        'flex items-center gap-4 p-4',
        'typo-body4 font-400',
        'text-Gray-900',
        className,
      )}
    >
      <RadixCheckbox.Root
        checked={turned}
        className={cn(checkboxVariants({ checked: turned, disabled }))}
        disabled={disabled}
        onCheckedChange={(state) => {
          const checked = state.valueOf();

          if (typeof checked !== 'boolean')
            throw new Error('checked is not boolean');

          onCheckedChange?.(checked);
          setTurned(checked);
        }}
        {...props}
      >
        <RadixCheckbox.Indicator>
          <svg
            fill="none"
            height="8"
            viewBox="0 0 10 8"
            width="10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.84998 0.0342066L8.96728 0.00623386C8.99038 0.000697772 9.08635 -0.00158285 9.18053 0.0011657C9.32616 0.00541523 9.37324 0.0159804 9.49551 0.0718481C9.93989 0.274909 10.124 0.753625 9.91226 1.15538C9.88152 1.21374 8.65774 2.64989 7.03759 4.52896C4.76204 7.16814 4.19664 7.81288 4.11399 7.86276C3.85282 8.02044 3.52669 8.04387 3.25337 7.92461C3.12171 7.86717 0.306588 5.90129 0.212848 5.80133C0.0507154 5.62844 -0.0321884 5.35453 0.0116154 5.13651C0.112599 4.63421 0.678898 4.33469 1.18835 4.51415C1.2721 4.54364 1.6097 4.76721 2.38382 5.30575C2.97717 5.71854 3.46561 6.05626 3.46926 6.05626C3.47291 6.05626 4.6103 4.74004 5.99677 3.1313C8.29616 0.463312 8.52891 0.199568 8.64615 0.129237C8.71683 0.0868192 8.80855 0.0440702 8.84998 0.0342066Z"
              fill="white"
            />
          </svg>
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
      {props.children}
    </label>
  );
};

export { Checkbox, type CheckboxProps };
