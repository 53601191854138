import { Mark, mergeAttributes } from '@tiptap/core';

import { ATTRIBUTES } from '../_constants';

export const FONT_SIZES = [
  'typo-h4',
  'typo-h5',
  'typo-h6',
  'typo-body1',
  'typo-body2',
  'typo-body3',
  'typo-body4',
] as const;

export type FontSizeType = (typeof FONT_SIZES)[number];

interface FontSizeOptions {
  HTMLAttributes: Record<string, any>;
}

interface FontSizeAttributes {
  fontSize: FontSizeType | null;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontSize: {
      setFontSize: (fontSize: FontSizeType) => ReturnType;
    };
  }
}

export const FontSize = Mark.create<FontSizeOptions>({
  name: 'fontSize',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      fontSize: {
        default: 'typo-body3' satisfies FontSizeType,
        parseHTML: (element: HTMLElement) =>
          element.getAttribute(ATTRIBUTES.FONT_SIZE),
        renderHTML: (attributes: FontSizeAttributes) => {
          if (!attributes.fontSize) {
            return {};
          }

          return {
            [ATTRIBUTES.FONT_SIZE]: attributes.fontSize,
            class: attributes.fontSize,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[${ATTRIBUTES.FONT_SIZE}]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }: FontSizeOptions) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setFontSize:
        (fontSize: FontSizeType) =>
        ({ chain }) => {
          let changedCommand = chain();

          if (fontSize.startsWith('typo-h')) {
            const level = Number(fontSize.replace('typo-h', '')) as 4 | 5 | 6;

            changedCommand = changedCommand.setHeading({ level });
          }

          return changedCommand.setMark(this.name, { fontSize }).run();
        },
    };
  },
});
