import React from 'react';

import { Slot } from '@radix-ui/themes';
import { NodeViewWrapper, type NodeViewProps } from '@tiptap/react';

import { SvgDelete } from '@common/icon';

export const ImageNodeView = ({ node, editor, getPos }: NodeViewProps) => {
  const isEditable = editor.isEditable;

  const src: string = node.attrs.src;

  return (
    <Slot className="w-full relative">
      <NodeViewWrapper as="div">
        {isEditable ? (
          <button
            className="absolute top-12 right-12 bg-grayscale-black opacity-50 w-28 h-28 flex justify-center items-center rounded-pill"
            type="button"
            onClick={() => {
              const from = getPos();
              const to = from + node.nodeSize;

              const marks = editor.state.doc.resolve(from - 1).marks();

              //! 이미지 삽입 후 삭제 다음 텍스트 입력 없이 버튼으로 삭제 시 마크 손실 방지
              let chainedCommand = editor.chain().deleteRange({
                from,
                to,
              });

              marks.forEach((mark) => {
                chainedCommand = chainedCommand.setMark(mark.type, mark.attrs);
              });

              chainedCommand.run();
            }}
          >
            <SvgDelete className="text-Gray-white" name="X" />
          </button>
        ) : null}
        <img
          alt="editor-img"
          className="w-full"
          draggable={false}
          loading="lazy"
          src={src}
        />
      </NodeViewWrapper>
    </Slot>
  );
};
