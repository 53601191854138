'use client';

import type { ReactNode } from 'react';
import { useState } from 'react';
import { flushSync } from 'react-dom';

import {
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useFloating,
} from '@floating-ui/react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { Slot } from '@radix-ui/themes';

import { cn } from '@common/utils';

interface TooltipProps {
  children: ReactNode;
  onOpenChange?: (value: boolean) => void;
  defaultOpen?: boolean;
  floatingPadding?: number;
  delayDuration?: number;
  asChild?: boolean;
  label: React.ReactNode;
  className?: string;
  triggerClassName?: string;
}

export const Tooltip = ({
  children,
  defaultOpen = false,
  onOpenChange,
  label,
  floatingPadding = 10,
  asChild,
  className,
  delayDuration = 200,
  triggerClassName,
}: TooltipProps) => {
  const [isOpened, setIsOpened] = useState(defaultOpen);

  const [maxHeight, setMaxHeight] = useState<number>();

  const { context } = useFloating({
    onOpenChange: (value: boolean) => {
      setIsOpened(value);
      onOpenChange?.(value);
    },
    open: isOpened,
    middleware: [
      offset(floatingPadding),
      flip(),
      shift({ padding: floatingPadding }),
      size({
        apply({ availableHeight }) {
          flushSync(() => {
            setMaxHeight(availableHeight - floatingPadding);
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  context.floatingStyles.maxHeight = maxHeight;

  const Button = asChild ? Slot : 'button';

  const toggle = () => {
    setIsOpened((prev) => !prev);
  };

  return (
    <RadixTooltip.Provider delayDuration={delayDuration}>
      <RadixTooltip.Root
        defaultOpen={defaultOpen}
        delayDuration={0}
        open={isOpened}
        onOpenChange={toggle}
      >
        <RadixTooltip.Trigger asChild onClick={toggle}>
          <Button className={triggerClassName}>{children}</Button>
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className={cn(
              'bg-Gray-black bg-opacity-70 rounded-small overflow-auto p-12 break-all whitespace-pre-wrap',
              'text-Gray-white typo-caption1 font-400',
              'max-w-180 z-10',
              className,
            )}
          >
            {label}
            <RadixTooltip.Arrow className="opacity-70" />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};
