'use client';

import type { HTMLTagProps } from '@common/types';

import { forwardRef, useEffect, useMemo, useRef } from 'react';

import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@common/utils';

const textAreaVariants = cva(
  'flex justify-center items-center px-16 bg-Gray-white border-grayscale-300 border-1 hide-scrollbar transition-colors has-[textarea:focus]:border-grayscale-900',
  {
    variants: {
      dimensions: {
        //! 텍스트 라인 24px 기준 py = (h - 24)/2
        H40: 'h-40 py-8',
        H48: 'h-48 py-12',
        H56: 'h-56 py-16',
      },
      roundness: {
        rectangle: 'rounded-small',
        capsule: 'rounded-pill',
      },
    },

    defaultVariants: {
      dimensions: 'H48',
      roundness: 'rectangle',
    },
  },
);

interface TextAreaProps
  extends HTMLTagProps<'textarea'>,
    VariantProps<typeof textAreaVariants> {
  autoResize?: boolean;
  textareaClassName?: string;
  maxLines?: number;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}

//! px 단위
const ROOT_FONT_SIZE = 16;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      autoResize = true,
      dimensions = 'H48',
      className,
      roundness,
      textareaClassName,
      leftContent,
      rightContent,
      maxLines,
      onChange,
      value,
      ...restTextareaProps
    }: TextAreaProps,
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const _textareaRef = useRef<HTMLTextAreaElement>(null);
    const textareaRef = (ref ?? _textareaRef) as typeof _textareaRef;

    const handleDynamicHeight = useMemo(() => {
      if (!autoResize) return null;

      return () => {
        //! 모든 단위 계산은 rem으로 변환하여 계산
        const [defailtHeight, paddingY] = (() => {
          switch (dimensions) {
            case 'H40':
              return [40, 8];
            case 'H56':
              return [56, 16];
            default:
              return [48, 12];
          } //! root font size
        })().map((v) => v / ROOT_FONT_SIZE);

        if (!textareaRef.current || !containerRef.current) return;

        const textareaStyle = textareaRef.current.style;

        textareaStyle.height = `${defailtHeight - paddingY * 2}rem`;

        const scrollHeightRem =
          textareaRef.current.scrollHeight / ROOT_FONT_SIZE;

        const textareaHeight =
          Math.ceil(scrollHeightRem) <= defailtHeight - paddingY * 2
            ? defailtHeight - paddingY * 2
            : scrollHeightRem;

        textareaStyle.height = `${textareaHeight}rem`;

        if (maxLines && textareaHeight >= (maxLines * 24) / ROOT_FONT_SIZE) {
          textareaStyle.maxHeight = `${(maxLines * 24) / ROOT_FONT_SIZE}rem`;
          textareaStyle.overflow = 'auto';
        }

        containerRef.current.style.height = 'fit-content';
      };
    }, [autoResize, dimensions, maxLines, textareaRef]);

    useEffect(() => {
      //! 외부 state를 통한 value 변경에 대한 처리
      if (value === undefined) return;

      handleDynamicHeight?.();
    }, [handleDynamicHeight, value]);

    return (
      <div
        ref={containerRef}
        className={cn(
          textAreaVariants({
            dimensions,
            roundness,
          }),
          className,
        )}
      >
        {leftContent}
        <textarea
          {...restTextareaProps}
          ref={textareaRef}
          className={cn(
            'flex-1 h-full bg-transparent outline-none resize-none placeholder:text-Gray-500 typo-inherit overflow-hidden hide-scrollbar',
            textareaClassName,
          )}
          value={value}
          onChange={(e) => {
            onChange?.(e);

            if (value === undefined) {
              handleDynamicHeight?.();
            }
          }}
        />
        {rightContent}
      </div>
    );
  },
);

TextArea.displayName = 'TextArea';
