import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';

import { isEqual } from 'es-toolkit';

import { useMountedEffect } from './useMountedEffect';

export type DisableSetter = () => void;

/**
 * @returns [value, setter, disableSetter]
 * @description 구독된 상태가 변경되면 업데이트 되는 상태값과 setter를 반환합니다.
 */
export function useSubscribedState<T>(
  subscribedState: T | (() => T),
  dependencyList?: React.DependencyList | false,
): [T, Dispatch<SetStateAction<T>>] {
  const valueState = useState(subscribedState);

  const [value, setValue] = valueState;

  const subscribedValue =
    subscribedState instanceof Function ? subscribedState() : subscribedState;

  useMountedEffect(
    () => {
      if (dependencyList) return;

      const subscribedValueType = typeof subscribedValue;

      switch (subscribedValueType) {
        case 'object':
        case 'function':
          if (!isEqual(value, subscribedValue)) setValue(subscribedValue);
          break;
        default:
          setValue(subscribedValue);
      }
    },
    dependencyList || [subscribedValue],
  );

  return dependencyList === false
    ? [
        subscribedValue,
        () => {
          console.info('disabled setter');
        },
      ]
    : valueState;
}

export type SubscribedState<T> = ReturnType<typeof useSubscribedState<T>>;
