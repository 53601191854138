'use client';

import type { Editor } from '@tiptap/core';
import type { EditorOptions, Extensions } from '@tiptap/react';

import type { ReactNode } from 'react';
import { createContext } from 'react';

import Image from '@tiptap/extension-image';
import ListItem from '@tiptap/extension-list-item';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import { ReactNodeViewRenderer, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import { cn } from '@common/utils';

import { Contents } from './Contents';
import { ImageButton } from './ImageButton';
import { Toolbar } from './Toolbar';
import { ImageNodeView } from './_components/ImageNodeView';
import { TEXT_ALIGN } from './_constants';
import BoxedText from './_utils/BoxedText';
import { CustomLink } from './_utils/CustomLink';
import { FontSize } from './_utils/FontSize';
import { FontWeight } from './_utils/FontWeight';
import { Highlight } from './_utils/Highlight';
import { PasteFilter } from './_utils/PasteFilter';
import { TextColor } from './_utils/TextColor';

export interface CustomEditorProviderProps
  extends Partial<
    Pick<
      EditorOptions,
      'onUpdate' | 'content' | 'onFocus' | 'onBlur' | 'onCreate'
    >
  > {
  children?: ReactNode;
  placeholder?: string;
  placeholderClass?: string;
}

interface CustomEditorContextValue {
  editor: Editor | null;
}

export const CustomEditorContext = createContext<CustomEditorContextValue>({
  editor: null,
});

const FIXED_EXTENSIONS: Extensions = [
  FontWeight,
  Highlight,
  BoxedText,
  FontSize,
  CustomLink,
  PasteFilter,
  Image.extend({
    addAttributes() {
      return {
        ...this.parent?.(),
      };
    },
    addNodeView() {
      return ReactNodeViewRenderer(ImageNodeView);
    },
  }).configure({
    HTMLAttributes: {
      class: 'w-full',
    },
  }),
  TextColor,
  TextStyle.configure({
    HTMLAttributes: { types: [ListItem.name, StarterKit.name] },
  }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    heading: {
      levels: [4, 5, 6],
    },
    paragraph: {
      HTMLAttributes: {
        class: 'min-h-24 typo-body3 font-400 Gray-black',
      },
    },
    horizontalRule: {
      HTMLAttributes: {
        class: 'border-t-1 border-Gray-300 my-30',
      },
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: true,
    },
  }),
];

const Provider = ({
  children,
  placeholder = '',
  placeholderClass,
  ...editorProps
}: CustomEditorProviderProps) => {
  const editor = useEditor({
    ...editorProps,
    extensions: FIXED_EXTENSIONS.concat([
      TextAlign.configure({
        types: ['heading', 'paragraph'],
        alignments: TEXT_ALIGN,
      }),
      Placeholder.configure({
        placeholder,
        emptyNodeClass: cn(
          'first:before:text-Gray-500 first:before:float-left first:before:content-[attr(data-placeholder)] first:before:pointer-events-none',
          placeholderClass,
        ),
      }),
    ]),
  });

  return (
    <CustomEditorContext.Provider
      value={{
        editor,
      }}
    >
      {children}
    </CustomEditorContext.Provider>
  );
};

export const CustomEditor = {
  Provider,
  Toolbar,
  Contents,
  ImageButton,
};
