'use client';

import type { HTMLTagProps } from '@common/types';

import { forwardRef } from 'react';

import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@common/utils';

const chipVariants = cva(
  'px-12 py-6 rounded-pill font-400 transition-colors border-1 cursor-pointer box-border inline-block',
  {
    variants: {
      theme: {
        primary:
          'bg-Gray-white text-Gray-700 border-Gray-300 has-[input:checked]:bg-Gray-900 has-[input:checked]:border-Gray-900 has-[input:checked]:text-Gray-white',
        'primary-line':
          'bg-Gray-white text-Gray-700 border-Gray-300 has-[input:checked]:border-Gray-900 has-[input:checked]:text-Gray-900',
        sub: 'bg-Gray-300 text-Gray-700 border-Gray-300 has-[input:checked]:bg-Gray-900 has-[input:checked]:border-Gray-900 has-[input:checked]:text-Gray-white',
      },
    },
    defaultVariants: {
      theme: 'primary',
    },
  },
);

export interface ChipProps
  extends Omit<HTMLTagProps<'input'>, 'type'>,
    VariantProps<typeof chipVariants> {
  onCheckedChange?: (checked: boolean) => void;
}

export const Chip = forwardRef<HTMLInputElement, ChipProps>(
  (
    {
      theme,
      children,
      onChange,
      onCheckedChange,
      className,
      ...restProps
    }: ChipProps,
    ref,
  ) => {
    return (
      <label className={cn(chipVariants({ theme, className }))}>
        <input
          {...restProps}
          ref={ref}
          className="hidden"
          type="checkbox"
          onChange={(e) => {
            onChange?.(e);
            onCheckedChange?.(e.target.checked);
          }}
        />
        {children}
      </label>
    );
  },
);

Chip.displayName = 'Chip';
