'use client';

import type { HTMLTagProps } from '@common/types';
import type { UseFloatingReturn } from '@floating-ui/react';
import type { Popover as RadixPopover } from '@radix-ui/themes';

import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { flushSync } from 'react-dom';

import {
  useFloating,
  offset,
  flip,
  shift,
  autoUpdate,
  size,
} from '@floating-ui/react';
import { Slot, Portal } from '@radix-ui/themes';

import { cn } from '@common/utils';

const PopoverContext = createContext<
  | (UseFloatingReturn['context'] & {
      handleMouseOverChange: Dispatch<SetStateAction<boolean>>;
      handleOpen: Dispatch<SetStateAction<boolean>>;
    })
  | null
>(null);

interface PopoverRootProps {
  children: ReactNode;
  onOpenChange?: (value: boolean) => void;
  defaultOpen?: boolean;
  open?: boolean;
  floatingPadding?: number;
  iframeCompatibility?: boolean;
}

export const UnControlledPopoverRoot = ({
  children,
  defaultOpen = false,
  open,
  onOpenChange,
  iframeCompatibility = false,
  floatingPadding = 10,
}: PopoverRootProps) => {
  const [isOpenedInternal, setIsOpenedInternal] = useState(defaultOpen);
  const isOpened = open !== undefined ? open : isOpenedInternal;
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [maxHeight, setMaxHeight] = useState<number>();

  const { context } = useFloating({
    onOpenChange: (value: boolean) => {
      if (open === undefined) {
        setIsOpenedInternal(value);
      }
      onOpenChange?.(value);
    },
    open: isOpened,
    middleware: [
      offset(floatingPadding),
      flip(),
      shift({ padding: floatingPadding }),
      size({
        apply({ availableHeight }) {
          flushSync(() => {
            setMaxHeight(availableHeight - floatingPadding);
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  context.floatingStyles.maxHeight = maxHeight;

  useEffect(() => {
    if (!open) return;

    const handleClickOutside = () => {
      if (isMouseOver) return;

      setIsOpenedInternal(false);
      onOpenChange?.(false);
    };

    document.addEventListener('mousedown', handleClickOutside);

    const iframes = iframeCompatibility
      ? document.querySelectorAll('iframe')
      : null;

    //! iframe 클릭을 감지 못하는 문제가 있음 iframe 태그 자체에 이벤트를 걸어줘야 함
    if (iframeCompatibility) {
      iframes?.forEach((iframe) => {
        const attachListener = () => {
          if (iframe.contentWindow) {
            iframe.contentWindow.document.addEventListener(
              'mousedown',
              handleClickOutside,
            );
          }
        };

        iframe.addEventListener('load', attachListener);
        attachListener();
      });
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (iframeCompatibility) {
        iframes?.forEach((iframe) => {
          if (iframe.contentWindow) {
            iframe.contentWindow.document.removeEventListener(
              'mousedown',
              handleClickOutside,
            );
          }
        });
      }
    };
  }, [iframeCompatibility, isMouseOver, onOpenChange, open]);

  return (
    <PopoverContext.Provider
      value={{
        ...context,
        handleOpen: setIsOpenedInternal,
        handleMouseOverChange: setIsMouseOver,
      }}
    >
      {children}
    </PopoverContext.Provider>
  );
};

interface PopoverTriggerProps extends Omit<HTMLTagProps<'button'>, 'ref'> {
  asChild?: boolean;
}

export const UnControlledPopoverTrigger = ({
  onClick,
  asChild,
  children,
  className,
  ...restProps
}: PopoverTriggerProps) => {
  const popoverContext = useContext(PopoverContext);

  if (!popoverContext)
    throw new Error(
      'Popover.Trigger 컴포넌트는 Popover.Root 컴포넌트 내부에서 사용되어야 합니다.',
    );

  const Button = asChild ? Slot : 'button';

  return (
    <Button
      {...restProps}
      ref={popoverContext.refs.setReference}
      className={cn('w-full', className)}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        popoverContext.onOpenChange(!popoverContext.open);
        onClick?.(e);
      }}
      onMouseEnter={() => {
        popoverContext.handleMouseOverChange(true);
      }}
      onMouseLeave={() => {
        popoverContext.handleMouseOverChange(false);
      }}
    >
      {children}
    </Button>
  );
};

interface PopoverContentProps extends Omit<RadixPopover.ContentProps, 'ref'> {
  asChild?: boolean;
  children?: ReactNode;
}

export const UnControlledPopoverContent = ({
  asChild,
  children,
  className,
  onMouseEnter,
  onMouseLeave,
  ...restProps
}: PopoverContentProps) => {
  const popoverContext = useContext(PopoverContext);

  if (!popoverContext)
    throw new Error(
      'Popover.Trigger 컴포넌트는 Popover.Root 컴포넌트 내부에서 사용되어야 합니다.',
    );

  const Content = asChild ? Slot : 'div';

  return popoverContext.open ? (
    <Portal asChild>
      <Content
        ref={popoverContext.refs.setFloating}
        className={cn(
          'bg-Gray-white rounded-medium shadow-popover overflow-auto p-8',
          className,
        )}
        style={popoverContext.floatingStyles}
        onClick={() => {
          popoverContext.handleOpen(false);
        }}
        onMouseEnter={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          popoverContext.handleMouseOverChange(true);
          onMouseEnter?.(e);
        }}
        onMouseLeave={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          popoverContext.handleMouseOverChange(false);
          onMouseLeave?.(e);
        }}
        {...restProps}
      >
        {children}
      </Content>
    </Portal>
  ) : null;
};

export const UnControlledPopover = {
  Root: UnControlledPopoverRoot,
  Trigger: UnControlledPopoverTrigger,
  Content: UnControlledPopoverContent,
};
