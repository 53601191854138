import type { HTMLTagProps } from '@common/types';

import { useContext } from 'react';

import { cn } from '@common/utils';

import { BottomDrawerContext } from '..';

interface BottomDrawerHandleWrapperProps extends HTMLTagProps<'div'> {}

export const HandleWrapper = ({
  children,
  className,
  ...restProps
}: BottomDrawerHandleWrapperProps) => {
  const context = useContext(BottomDrawerContext);

  if (!context)
    throw new Error(
      'BottomDrawer.HandleWrapper는 BottomDrawer 내부에서만 사용할 수 있습니다.',
    );

  return context.onClose ? (
    <div
      {...restProps}
      {...context.swipeableHandlers}
      className={cn(
        'w-full flex px-8 pt-13 pb-23 justify-center relative',
        className,
      )}
    >
      {children}
    </div>
  ) : (
    <div className="w-full h-36 relative" />
  );
};
