'use client';

import { useEffect, useState } from 'react';

import { SvgTestTube } from '@common/icon';
import { cn } from '@common/utils';

interface TestMarkProps {
  stageKeyword?: string;
  devKeyword?: string;
}

const SESSION_KEY = 'test-mark';

export const TestMark = ({
  devKeyword = 'dev-',
  stageKeyword = 'st-',
}: TestMarkProps) => {
  const [isShow, setIsShow] = useState(false);
  const [env, setEnv] = useState<'dev' | 'stage' | 'local'>();

  useEffect(() => {
    const { origin } = location;

    const isHidden = sessionStorage.getItem(SESSION_KEY) === 'hidden';

    if (!isHidden) {
      setIsShow(true);
    }

    setEnv(() => {
      if (origin.includes('localhost')) {
        return 'local';
      }
      if (origin.includes(devKeyword)) {
        return 'dev';
      }
      if (origin.includes(stageKeyword)) {
        return 'stage';
      }

      return;
    });
  }, [devKeyword, stageKeyword]);

  return isShow && env ? (
    <button
      className={cn('fixed top-10 left-10 rounded-pill p-5 opacity-50 z-50', {
        'bg-R-500': env === 'dev',
        'bg-Y-500': env === 'stage',
        'bg-B-500': env === 'local',
      })}
      type="button"
      onClick={() => {
        setIsShow(false);
        sessionStorage.setItem(SESSION_KEY, 'hidden');
      }}
    >
      <SvgTestTube className="size-15 text-grayscale-50" name="Test-Tube" />
    </button>
  ) : null;
};
